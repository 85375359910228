import React, { useEffect, useRef } from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import styled from 'styled-components';
import { srConfig } from '@config';
import sr from '@utils/sr';
import { usePrefersReducedMotion } from '@hooks';
import Slider from 'react-slick';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Certificates = () => {
  const StyledSlide = styled.div`
    padding: 0 8px;
    box-sizing: border-box;
  `;

  const StyledCentredDiv = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
  `;

  const data = useStaticQuery(graphql`
    query {
      certificates: allMarkdownRemark(
        filter: {
          fileAbsolutePath: { regex: "/certificates/" }
          frontmatter: { showInCertificates: { ne: false } }
        }
        sort: { fields: [frontmatter___date], order: DESC }
      ) {
        edges {
          node {
            frontmatter {
              date
              title
              cover {
                childImageSharp {
                  gatsbyImageData(width: 700, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
                }
              }
              external
              organization
              skills
            }
            html
          }
        }
      }
    }
  `);

  const certificatesData = data.certificates.edges;
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: 'linear',
    pauseOnHover: true,
    adaptiveHeight: true,
    arrows: false,
  };
  const revealTitle = useRef(null);
  const revealSlides = useRef([]);
  const revealLink = useRef(null);
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    if (prefersReducedMotion) {
      return;
    }

    sr.reveal(revealTitle.current, srConfig());
    revealSlides.current.forEach((ref, i) => sr.reveal(ref, srConfig(i * 100)));
  }, []);

  return (
    <section id="certificates">
      <h2 className="numbered-heading" ref={revealTitle}>
        My Licenses & certifications
      </h2>

      <Slider {...settings}>
        {certificatesData &&
          certificatesData.map(({ node }, i) => {
            const { frontmatter } = node;
            const { external, title, cover } = frontmatter;
            const image = getImage(cover);
            return (
              <div key={i} ref={el => (revealSlides.current[i] = el)}>
                <StyledSlide className="certificate-image">
                  <a href={external} target="_blank" rel="nofollow noopener noreferrer">
                    <GatsbyImage image={image} alt={title} className="img" />
                  </a>
                </StyledSlide>
              </div>
            );
          })}
      </Slider>

      <StyledCentredDiv ref={revealLink}>
        <Link className="inline-link archive-link" to="/certificates">
          view all
        </Link>
      </StyledCentredDiv>
    </section>
  );
};

export default Certificates;
